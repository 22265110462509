import React from "react"

import Layout from "../../components/Layout"

import { Link } from "gatsby"

import origens from "../../images/origens/origens1_r.jpg";
import map from "../../images/origens/map.png";

export default function Home() {
  return (
    <Layout breadcrumbs={["Origens Brasil"]}>
      <div id="heading4" >
        <h1 className="origens">Origens Brasil<span className="registered">®</span></h1>
      </div>

      {/* Main */}
      <section id="main" className="wrapper">
        <div className="inner">

          <div className="content page">

            {<h5><Link to="/">Inicio</Link> &gt;&gt; Projetos  &gt;&gt; Origens Brasil<span className="registered">®</span></h5>}

            <header>
              <h2>Garantia de origem pela floresta em pé</h2>
            </header>
            <p className="headline">Atividades econômicas que provêm sustento para as pessoas não precisam levar à destruição do meio-ambiente!</p>
            <p><span className="image right desktop-only"><img src={origens} alt="" /></span> O Origens Brasil<span className="registered">®</span> é uma rede
              de comércio ético que promove negócios sustentáveis na Amazônia, em áreas prioritárias de conservação, ao oferecer garantia de origem,
              transparência e rastreabilidade da cadeia produtiva. Atualmente administrada pelo IMAFLORA (Instituto de Manejo e Certificação Florestal
              e Agrícola), a rede conecta populações tradicionais, povos indígenas, organizações comunitárias, instituições de apoio e empresas.
            </p>
              <span className="image-page mobile-only"><img src={origens} /></span>
            <p>O Origens Brasil<span className="registered">®</span> possui
              duas frentes de inovação. A primeira é a plataforma tecnológica de transparência e rastreabilidade,
              customizada e adaptada à realidade da Amazônia, com uma interface intuitiva e fácil de ser operada pelos povos
              da floresta. A segunda diz respeito à metodologia de operação da rede, que se adapta às realidades dos povos,
              em um processo bottom-up de construção coletiva de requisitos e garantia de transparência das informações e conexões comerciais.
            </p>
              <span className="image-page mobile-only"><img src={map} /></span>
            <p><span className="image left desktop-only"><img src={map} alt="" /></span>
              O Engajados é responsável pelo desenvolvimento e suporte técnico da plataforma. Desde 2018, trabalhamos em
              conjunto com a equipe do Imaflora na absorção da tecnologia legada e no desenvolvimento de novas plataformas,
              construídas em cima do nosso framework “Dorothy”. Toda a tecnologia está sendo refatorada, expandida e
              organizada em torno da facilidade de colaboração e do engajamento de todos os membros da Rede Origens Brasil<span className="registered">®</span>.
            </p>

            <br />
            <br />
            <br />
            <ul>
              <li>Acesse o projeto em: <a href="https://www.origensbrasil.org.br/" target="_blank">www.origensbrasil.org.br</a></li>
            </ul>

          </div>
        </div>
      </section>
    </Layout>
  )
}
